.site-layout-background .ant-collapse-header .ant-collapse-arrow {
  color: white !important; /* Cambia el color del ícono (flecha) a blanco */
}

.site-layout-background .ant-collapse-header i {
  color: white !important; /* Cambia el color del ícono a blanco */
}

/* Estilos para la tabla específica */
.table-curso-fortalecimiento .custom-ant-table-wrapper .ant-table-cell,
.table-curso-fortalecimiento .custom-ant-table-wrapper .ant-table-thead>tr>th,
.table-curso-fortalecimiento .custom-ant-table-wrapper .ant-table-tbody>tr>th,
.table-curso-fortalecimiento .custom-ant-table-wrapper .ant-table-tbody>tr>td,
.table-curso-fortalecimiento .custom-ant-table-wrapper tfoot>tr>th,
.table-curso-fortalecimiento .custom-ant-table-wrapper tfoot>tr>td {
  position: relative !important;
  
}

.table-curso-fortalecimiento .custom-ant-table-wrapper thead {
  border-color: #ffffff !important;
  border-style: solid !important;
  border-width: 1px !important;
}
/* Eliminar el borde de separación en las celdas de la tabla */
.table-curso-fortalecimiento .ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.table-curso-fortalecimiento .ant-table-wrapper .ant-table-thead > tr > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  content: none !important;
  /* Eliminar los estilos de borde */
  background-color: transparent !important;
  width: 0 !important;
  height: 0 !important;
  transform: none !important;
}

:where(.css-dev-only-do-not-override-1gwfwyx).ant-table-wrapper .ant-table-cell, :where(.css-dev-only-do-not-override-1gwfwyx).ant-table-wrapper .ant-table-thead>tr>th{
  position: relative;
  padding: 4px 4px; 
  
  
}
thead.ant-table-thead {
border-color: #ffffff !important;
border-style: solid!important;;
}






/* Control del hover */
/* Eliminar el color de hover en todas las filas */

/* 1. Asegurar que todas las filas inician con fondo blanco */
.ant-table-tbody > tr {
  background: white !important;
}

/* 2. Hover en color azul claro para todas las filas, incluyendo filas combinadas */
.ant-table-tbody > tr:hover,
.ant-table-tbody > tr:hover .ant-table-cell {
  background: #b3d5f4 !important;
}

/* 3. Hover en celdas individuales */
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #b3d5f4 !important;
}
/* 

.fila-seleccionada,
.fila-seleccionada .ant-table-cell {
  background: #82bcf3 !important;
}


.fila-seleccionada + tr,
.fila-seleccionada + tr .ant-table-cell {
  background: #82bcf3 !important;
}


.fila-seleccionada:hover,
.fila-seleccionada:hover .ant-table-cell {
  background: #82bcf3 !important;
}


.fila-seleccionada + tr:hover,
.fila-seleccionada + tr:hover .ant-table-cell {
  background: #82bcf3 !important;
}
*/



.ant-table-thead > tr > th.color2024 {
  background-color: #408cc8 !important;
  color: white !important;
}



.ant-table-thead > tr > th.color2025 {
  background-color: #198754 !important;
  color: white !important;
}

.ant-table-thead > tr > th.color2026 {
  background-color: #eea72c !important;
  color: white !important;
}


.custom-collapse .ant-collapse-item {
  margin-bottom: 10px !important; /* Ajusta el valor según lo que necesites */
}
